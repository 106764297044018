import { AuthGuard } from './core/guard/auth.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MobilePlatformGuard } from './core/guard/mobile-platform.guard';
import { RedirectGuard } from './core/guard/redirect.guard';

const routes: Routes = [
	{
		path: '',
		redirectTo: '/welcome-app',
		pathMatch: 'full'
	},
	{
		path: 'welcome-app',
		loadChildren: () => import('./pages/welcome/welcome.module').then((m) => m.WelcomePageModule),
		data: {
			title: 'Movsa: Bienvenido'
		},
		canActivate: [RedirectGuard, MobilePlatformGuard]
	},
	{
		path: 'login',
		loadChildren: () => import('./pages/loginWeb/login-web.module').then((m) => m.LoginWebPageModule),
		data: {
			title: 'Movsa: Login'
		}
	},
	{
		path: 'politica',
		loadChildren: () => import('./pages/policyWeb/policy.module').then((m) => m.PolicyModule),
		data: {
			title: 'Movsa: Política'
		}
	},
	{
		path: 'registro',
		loadChildren: () => import('./pages/registerWeb/register-web.module').then((m) => m.RegisterWebPageModule),
		data: {
			title: 'Movsa: Registro'
		}
	},
	{
		path: 'perfil',
		loadChildren: () => import('./pages/profileWeb/profile-web.module').then((m) => m.ProfileWebPageModule),
		data: {
			title: 'Movsa: Perfil'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'diario',
		loadChildren: () => import('./pages/diaryWeb/diary-web.module').then((m) => m.DiaryWebPageModule),
		data: {
			title: 'Movsa: Diario'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'diario/comparar/:residentId/:sessionId/:type',
		loadChildren: () => import('./pages/diary-compare-web/diary-compare-web.module').then((m) => m.DiaryCompareWebPageModule),
		data: {
			title: 'Movsa: Diario'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'planif',
		loadChildren: () => import('./pages/planifWeb/planif-web.module').then((m) => m.PlanifWebPageModule),
		data: {
			title: 'Movsa: Protocolo'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'planif/comparar/:residentId/:sessionId/:type',
		loadChildren: () => import('./pages/planif-compare-web/planif-compare-web.module').then((m) => m.PlanifCompareWebPageModule),
		data: {
			title: 'Movsa: Protocolo'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'directo',
		loadChildren: () => import('./pages/directWeb/direct-web.module').then((m) => m.DirectWebPageModule),
		data: {
			title: 'Movsa: Directo'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'avisos',
		loadChildren: () => import('./pages/noticeWeb/notice-web.module').then((m) => m.NoticeWebModule),
		data: {
			title: 'Movsa: Avisos'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'invitaciones',
		loadChildren: () => import('./pages/invitationWeb/invitation-web.module').then((m) => m.InvitationWebPageModule),
		data: {
			title: 'Movsa: Invitaciones'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'dispositivos',
		loadChildren: () => import('./pages/deviceWeb/device-web.module').then((m) => m.DeviceWebPageModule),
		data: {
			title: 'Movsa: Dispositivos'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'monitores',
		loadChildren: () => import('./pages/bleDeviceWeb/bleDevice-web.module').then((m) => m.BleDeviceWebPageModule),
		data: {
			title: 'Movsa: Monitores'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'centros',
		loadChildren: () => import('./pages/centerWeb/center-web.module').then((m) => m.CenterWebPageModule),
		data: {
			title: 'Movsa: Centros Movsa'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'usuarios',
		loadChildren: () => import('./pages/userWeb/user-web.module').then((m) => m.UserWebPageModule),
		data: {
			title: 'Movsa: Usuarios Movsa'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'sesiones',
		loadChildren: () => import('./pages/sessionWeb/session-web.module').then((m) => m.SessionWebPageModule),
		data: {
			title: 'Movsa: Sesiones Movsa'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'login-app',
		loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
		data: {
			title: 'Movsa: Login'
		},
		canActivate: [MobilePlatformGuard]
	},
	{
		path: 'registro-app',
		loadChildren: () => import('./pages/register/register.module').then((m) => m.RegisterPageModule),
		data: {
			title: 'Movsa: Registro'
		},
		canActivate: [MobilePlatformGuard]
	},
	{
		path: 'notificaciones-app',
		loadChildren: () => import('./pages/notifications/notifications.module').then((m) => m.NotificationsPageModule),
		data: {
			title: 'Movsa: Notificaciones'
		},
		canActivate: [MobilePlatformGuard, AuthGuard]
	},
	{
		path: 'diario-app',
		loadChildren: () => import('./pages/diary/diary.module').then((m) => m.DiaryPageModule),
		data: {
			title: 'Movsa: Diario'
		},
		canActivate: [MobilePlatformGuard, AuthGuard],
		runGuardsAndResolvers: 'always'
	},
	{
		path: 'perfil-app',
		loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
		data: {
			title: 'Movsa: Perfil'
		},
		canActivate: [MobilePlatformGuard]
	},
	{
		path: 'terminos-app',
		loadChildren: () => import('./pages/terms/terms.module').then((m) => m.TermsPageModule),
		data: {
			title: 'Movsa: Terminos y condiciones'
		},
		canActivate: [MobilePlatformGuard]
	},
	{
		path: 'privacidad-app',
		loadChildren: () => import('./pages/privacy/privacy.module').then((m) => m.PrivacyPageModule),
		data: {
			title: 'Movsa: Politica de privacidad'
		},
		canActivate: [MobilePlatformGuard]
	},
	{
		path: 'avisos-app',
		loadChildren: () => import('./pages/alerts/alerts.module').then((m) => m.AlertsPageModule),
		data: {
			title: 'Movsa: Avisos'
		},
		canActivate: [MobilePlatformGuard, AuthGuard]
	},
	{
		path: 'directo-app',
		loadChildren: () => import('./pages/live/live.module').then((m) => m.LivePageModule),
		data: {
			title: 'Movsa: Directo'
		},
		canActivate: [MobilePlatformGuard, AuthGuard]
	},
	{
		path: 'bleDevices-app',
		loadChildren: () => import('./pages/bleDevices/bleDevices.module').then((m) => m.BleDevicesPageModule),
		data: {
			title: 'Movsa: Dispositivos'
		},
		canActivate: [MobilePlatformGuard, AuthGuard]
	},
	{
		path: 'configuracion-app',
		loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsPageModule),
		data: {
			title: 'Movsa: Configuracion'
		},
		canActivate: [MobilePlatformGuard, AuthGuard]
	},
	{
		path: 'contacto-app',
		loadChildren: () => import('./pages/contact/contact.module').then((m) => m.ContactPageModule),
		data: {
			title: 'Movsa: Contacto'
		},
		canActivate: [MobilePlatformGuard, AuthGuard]
	},
	{
		path: 'perfil-monitorizado-app',
		loadChildren: () => import('./pages/resident-profile/resident-profile.module').then((m) => m.ResidentProfilePageModule),
		data: {
			title: 'Movsa: Perfil Monitorizado'
		},
		canActivate: [MobilePlatformGuard, AuthGuard]
	},
	{
		path: 'eventos-app',
		loadChildren: () => import('./pages/events/events.module').then((m) => m.EventsPageModule),
		data: {
			title: 'Movsa: Eventos'
		},
		canActivate: [MobilePlatformGuard, AuthGuard]
	},
	{
		path: 'refresh',
		loadChildren: () => import('./pages/refresh/refresh.module').then((m) => m.RefreshPageModule)
	},
	{
		path: 'diary-compare-web',
		loadChildren: () => import('./pages/diary-compare-web/diary-compare-web.module').then((m) => m.DiaryCompareWebPageModule)
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
	exports: [RouterModule]
})
export class AppRoutingModule {}

import { API } from '../../global/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Plugins } from '@capacitor/core';
import { LoginVM } from '../interfaces/loginVM';
import { Observable } from 'rxjs';

const { Storage } = Plugins;

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	constructor(private http: HttpClient) {}

	login(user: string, pass: string, remember: boolean): Observable<any> {
		const body: LoginVM = {
			username: user,
			password: pass,
			rememberMe: remember
		};
		return this.http.post(API.LOGIN, body);
	}

	logout() {
		return Storage.clear();
	}

	isLogged(): Promise<{ value: string | null }> {
		return Storage.get({ key: 'id_token' });
	}

	getRole(): Promise<{ value: string | null }> {
		return Storage.get({ key: 'rolUser' });
	}
}

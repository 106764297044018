import { environment } from '../../environments/environment';

export const API = Object.freeze({
	// ACCOUNT RESOURCE
	GET_ACCOUNT: environment.urlApi + 'account',
	SAVE_ACCOUNT: environment.urlApi + 'account',
	CHANGE_PASSWORD: environment.urlApi + 'account/change-password',
	FINISH_PASSWORD_RESET: environment.urlApi + 'account/reset-password/finish',
	FINISH_PASSWORD_ACTIVATE: environment.urlApi + 'account/activate-password/finish',
	REQUEST_PASSWORD_RESET: environment.urlApi + 'account/reset-password/init',
	ACTIVATE_ACCOUNT: environment.urlApi + 'activate',
	IS_AUTHENTICATED: environment.urlApi + 'authenticate',
	REGISTER_ACCOUNT: environment.urlApi + 'register',

	// ARRHYTHMIA RESOURCE
	GET_ALL_ARRHYTHMIAS: environment.urlApi + 'arrhythmias',
	CREATE_ARRHYTHMIA: environment.urlApi + 'arrhythmias',
	UPDATE_ARRHYTHMIA: environment.urlApi + 'arrhythmias',
	DELETE_ARRHYTHMIA: environment.urlApi + 'arrhythmias/{id}',
	GET_ARRHYTHMIA: environment.urlApi + 'arrhythmias/{id}',

	// ARRHYTHMIA ONLINE RESOURCE
	GET_ALL_ARRHYTHMIAS_ONLINE: environment.urlApi + 'arrhythmia-onlines',
	CREATE_ARRHYTHMIA_ONLINE: environment.urlApi + 'arrhythmia-onlines',
	UPDATE_ARRHYTHMIA_ONLINE: environment.urlApi + 'arrhythmia-onlines',
	DELETE_ARRHYTHMIA_ONLINE: environment.urlApi + 'arrhythmia-onlines/{id}',
	GET_ARRHYTHMIA_ONLINE: environment.urlApi + 'arrhythmia-onlines/{id}',

	// DATA SESSION RESOURCE
	GET_ALL_DATA_SESSIONS: environment.urlApi + 'data-sessions',
	CREATE_DATA_SESSION: environment.urlApi + 'data-sessions',
	UPDATE_DATA_SESSION: environment.urlApi + 'data-sessions',
	DELETE_DATA_SESSION: environment.urlApi + 'data-sessions/{id}',
	GET_DATA_SESSION: environment.urlApi + 'data-sessions/{id}',
	GET_DATA_SESSION_FILTERS: environment.urlApi + 'data-sessions-filters',
	SEARCH_DATA_SESSION: environment.urlApi + 'datasessions/search',

	// DEVICE RESOURCE
	GET_ALL_DEVICES: environment.urlApi + 'devices',
	CREATE_DEVICE: environment.urlApi + 'devices',
	CREATE_DEVICEDAY: environment.urlApi + 'devicesday',
	UPDATE_DEVICE: environment.urlApi + 'devices',
	DELETE_DEVICE: environment.urlApi + 'devices/{id}',
	GET_DEVICE: environment.urlApi + 'devices/{id}',
	GET_DEVICEDAY_BY_DEVICEID: environment.urlApi + 'devicesday/{id}',
	DELETE_ALL_DEVICEDAY: environment.urlApi + 'devicesday/{id}',
	GET_DEVICES_BY_RESIDENCE: environment.urlApi + 'devices/residence/{id}',
	UPDATE_ECG_ONLINE: environment.urlApi + 'devices/ecg/activate',
	DELETE_DEVICE_RELATION: environment.urlApi + 'devices/relation/{id}',
	UPDATE_DEVICE_RESIDENT_TODAY: environment.urlApi + 'devicesday/update/{id}',
	//MONITORS
	GET_ALL_MONITORS: environment.urlApi + 'monitors',
	CREATE_MONITOR: environment.urlApi + 'monitors',
	UPDATE_MONITOR: environment.urlApi + 'monitors',
	DELETE_MONITOR: environment.urlApi + 'monitors/{id}',
	GET_MONITOR: environment.urlApi + 'monitors/{id}',
	GET_MONITORS_BY_RESIDENCE: environment.urlApi + 'monitors',

	// BLOOD PRESSURE RESOURCE
	GET_ALL_BLOOD_PRESSURES: environment.urlApi + 'blood-pressures',
	CREATE_BLOOD_PRESSURE: environment.urlApi + 'blood-pressures',
	UPDATE_BLOOD_PRESSURE: environment.urlApi + 'blood-pressures',
	DELETE_BLOOD_PRESSURE: environment.urlApi + 'blood-pressures/{id}',
	GET_BLOOD_PRESSURE: environment.urlApi + 'blood-pressures/{id}',

	// OXYGEN SATURATION RESOURCE
	GET_ALL_OXIGEN_SATURATIONS: environment.urlApi + 'oxygen-saturations',
	CREATE_OXIGEN_SATURATION: environment.urlApi + 'oxygen-saturations',
	UPDATE_OXIGEN_SATURATION: environment.urlApi + 'oxygen-saturations',
	DELETE_OXIGEN_SATURATION: environment.urlApi + 'oxygen-saturations/{id}',
	GET_OXIGEN_SATURATION: environment.urlApi + 'oxygen-saturations/{id}',

	// WEIGHT RESOURCE
	GET_ALL_WEIGHTS: environment.urlApi + 'weights',
	CREATE_WEIGHT: environment.urlApi + 'weights',
	UPDATE_WEIGHT: environment.urlApi + 'weights',
	DELETE_WEIGHT: environment.urlApi + 'weights/{id}',
	GET_WEIGHT: environment.urlApi + 'weights/{id}',

	// GROUP RESOURCE
	GET_ALL_GROUPS: environment.urlApi + 'groups',
	CREATE_GROUP: environment.urlApi + 'groups',
	UPDATE_GROUP: environment.urlApi + 'groups',
	DELETE_GROUP: environment.urlApi + 'groups/{id}',
	GET_GROUP: environment.urlApi + 'groups/{id}',

	// HEART RATE RESOURCE
	GET_ALL_HEART_RATES: environment.urlApi + 'heart-rates',
	CREATE_HEART_RATE: environment.urlApi + 'heart-rates',
	UPDATE_HEART_RATE: environment.urlApi + 'heart-rates',
	DELETE_HEART_RATE: environment.urlApi + 'heart-rates/{id}',
	GET_HEART_RATE: environment.urlApi + 'heart-rates/{id}',

	// HEART RATE ONLINE RESOURCE
	GET_ALL_HEART_RATES_ONLINE: environment.urlApi + 'heart-rates-onlines',
	CREATE_HEART_RATE_ONLINE: environment.urlApi + 'heart-rates-onlines',
	UPDATE_HEART_RATE_ONLINE: environment.urlApi + 'heart-rates-onlines',
	DELETE_HEART_RATE_ONLINE: environment.urlApi + 'heart-rates-onlines/{id}',
	GET_HEART_RATE_ONLINE: environment.urlApi + 'heart-rates-onlines/{id}',

	// ECG ONLINE RESOURCE
	GET_ALL_ECG_ONLINE: environment.urlApi + 'ecg-onlines',
	CREATE_ECG_ONLINE: environment.urlApi + 'ecg-onlines',
	// UPDATE_ECG_ONLINE: environment.urlApi + 'ecg-onlines',
	DELETE_ECG_ONLINE: environment.urlApi + 'ecg-onlines/{id}',
	GET_ECG_ONLINE: environment.urlApi + 'ecg-onlines/{id}',

	// MEDICAL RESOURCE
	GET_ALL_MEDICALS: environment.urlApi + 'medicals',
	CREATE_MEDICAL: environment.urlApi + 'medicals',
	UPDATE_MEDICAL: environment.urlApi + 'medicals',
	DELETE_MEDICAL: environment.urlApi + 'medicals/{id}',
	GET_MEDICAL: environment.urlApi + 'medicals/{id}',

	// OWQLO RESOURCE
	GET_ALL_OWQLOS: environment.urlApi + 'owqlos',
	CREATE_OWQLO: environment.urlApi + 'owqlos',
	UPDATE_OWQLO: environment.urlApi + 'owqlos',
	DELETE_OWQLO: environment.urlApi + 'owqlos/{id}',
	GET_OWQLO: environment.urlApi + 'owqlos/{id}',

	// RESIDENCE RESOURCE
	GET_ALL_RESIDENCES: environment.urlApi + 'residences',
	CREATE_RESIDENCE: environment.urlApi + 'residences',
	UPDATE_RESIDENCE: environment.urlApi + 'residences',
	DELETE_RESIDENCE: environment.urlApi + 'residences/{id}',
	GET_RESIDENCE: environment.urlApi + 'residences/{id}',
	SEARCH_RESIDENCE: environment.urlApi + 'residence/search',

	// RESIDENT RESOURCE
	GET_ALL_RESIDENTS: environment.urlApi + 'residents',
	GET_ALL_RESIDENTS_BY_RESIDENCE: environment.urlApi + 'residents/residences/{idResidence}',
	GET_MONITORED_RESIDENTS_BY_RESIDENCE: environment.urlApi + 'residents/monitored/residence/{idResidence}',
	CREATE_RESIDENT: environment.urlApi + 'residents',
	UPDATE_RESIDENT: environment.urlApi + 'residents',
	DELETE_RESIDENT: environment.urlApi + 'residents/{id}',
	DELETE_RESIDENTS: environment.urlApi + 'residents-list/{ids}',
	GET_RESIDENT: environment.urlApi + 'residents/{id}',
	SEARCH_RESIDENT: environment.urlApi + 'residents/search',

	// SESSION RESOURCE
	GET_ALL_SESSIONS: environment.urlApi + 'sessions',
	CREATE_SESSION: environment.urlApi + 'sessions',
	UPDATE_SESSION: environment.urlApi + 'sessions',
	DELETE_SESSION: environment.urlApi + 'sessions/{id}',
	GET_SESSION: environment.urlApi + 'sessions/{id}',

	// STEPS RESOURCE
	GET_ALL_STEPS: environment.urlApi + 'steps',
	CREATE_STEPS: environment.urlApi + 'steps',
	UPDATE_STEPS: environment.urlApi + 'steps',
	DELETE_STEPS: environment.urlApi + 'steps/{id}',
	GET_STEPS: environment.urlApi + 'steps/{id}',

	// TEMPERATURE RESOURCE
	GET_ALL_TEMPERATURES: environment.urlApi + 'temperatures',
	CREATE_TEMPERATURE: environment.urlApi + 'temperatures',
	UPDATE_TEMPERATURE: environment.urlApi + 'temperatures',
	DELETE_TEMPERATURE: environment.urlApi + 'temperatures/{id}',
	GET_TEMPERATURE: environment.urlApi + 'temperatures/{id}',

	// TEMPERATURE ONLINE RESOURCE
	GET_ALL_TEMPERATURES_ONLINE: environment.urlApi + 'temperature-onlines',
	CREATE_TEMPERATURE_ONLINE: environment.urlApi + 'temperature-onlines',
	UPDATE_TEMPERATURE_ONLINE: environment.urlApi + 'temperature-onlines',
	DELETE_TEMPERATURE_ONLINE: environment.urlApi + 'temperature-onlines/{id}',
	GET_TEMPERATURE_ONLINE: environment.urlApi + 'temperature-onlines/{id}',

	// USER JWT CONTROLLER
	LOGIN: environment.urlApi + 'authenticate',

	// USER RESOURCE
	GET_ALL_USERS: environment.urlApi + 'users',
	CREATE_USER: environment.urlApi + 'users',
	UPDATE_USER: environment.urlApi + 'users',
	GET_AUTHORITIES: environment.urlApi + 'users/authorities',
	DELETE_USER: environment.urlApi + 'users/{login}',
	GET_USER: environment.urlApi + 'users/{login}',
	GET_USERS_BY_RESIDENCE: environment.urlApi + 'users/residence/{id}',
	SEARCH_USER: environment.urlApi + 'user/search',

	// ADVISE RESOURCE
	GET_ALL_ADVISES: environment.urlApi + 'advises',
	CREATE_ADVISE: environment.urlApi + 'advises',
	UPDATE_ADVISE: environment.urlApi + 'advises',
	DELETE_ADVISE: environment.urlApi + 'advises/{id}',
	GET_ADVISE: environment.urlApi + 'advises/{id}',
	SEARCH_ADVISE: environment.urlApi + 'advises/search',
	MARK_ADVISE_AS_SEEN: environment.urlApi + 'advises/{id}',

	// EXCEL READER RESOURCE
	READ_DEVICE_FROM_EXCEL: environment.urlApi + 'excel/read-device/{id}',
	READ_RESIDENT_FROM_EXCEL: environment.urlApi + 'excel/read-resident/{id}',
	READ_USER_FROM_EXCEL: environment.urlApi + 'excel/read-user/{id}',

	// CSV READER RESOURCE
	READ_DEVICE_FROM_CSV: environment.urlApi + 'read-device/{id}',
	READ_RESIDENT_FROM_CSV: environment.urlApi + 'read-resident/{id}',
	READ_USER_FROM_CSV: environment.urlApi + 'read-user/{id}',

	// FIREBASE REGISTER
	REGISTER_FIREBASE: environment.urlApi + 'firebase/register/{id}',

	//INSTANT AVERAGES
	GET_INSTANT_AVERAGES: environment.urlApi + 'instant-averages',

	//RESIDENT ADVISE CONFIG
	GET_ADVISE_CONFIG_BY_RESIDENT: environment.urlApi + 'residents/advise-config/{id}',
	UPDATE_ADVISE_CONFIG_BY_RESIDENT: environment.urlApi + 'residents/advise-config',
	CREATE_ADVISE_CONFIG: environment.urlApi + 'residents/advise-config',

	//ADVISES FOR LIVE AND DIARY
	GET_ADVISES_DIARY: environment.urlApi + 'advises/diary',
	GET_ADVISES_LIVE: environment.urlApi + 'advises/direct',

	//ECG DATASESSION
	GET_ECG_DATASESSION: environment.urlApi + 'ecg-dataSession/{residentId}/{dataSessionId}'
});

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { API } from '../../global/api';
import { Plugins } from '@capacitor/core';
import { switchMap } from 'rxjs/operators';
const { Storage } = Plugins;

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
	constructor() {}
	private urls = [API.LOGIN, API.REQUEST_PASSWORD_RESET, API.FINISH_PASSWORD_RESET, API.GET_ALL_RESIDENCES];

	private static async getIdToken(): Promise<{ value: string | null }> {
		return await Storage.get({ key: 'id_token' });
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return from(AuthorizationInterceptor.getIdToken()).pipe(
			switchMap((token) => {
				// skip requests for local assets
				if (request.url.indexOf('assets/') >= 0 || (this.urls.includes(request.url) && request.method === 'GET')) {
					return next.handle(request);
				}
				const apiReq = request.clone({
					url: request.url,
					headers: request.headers.set('Authorization', `Bearer ${token.value}`)
				});
				//console.log("El valor del token pedido es:"+ token.value);
				return next.handle(apiReq);
			})
		);
	}
}

import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { Router } from '@angular/router';
import { IonList, IonSearchbar, Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { API } from '../../../global/api';
import { UserInterface } from '../../../shared/interfaces/userInterface';
import { Plugins } from '@capacitor/core';
import { ResidenceInterface } from '../../../shared/interfaces/residenceInterface';
import { HeaderService } from '../../services/header.service';
const { Storage } = Plugins;

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
	public deactivateRoutes: boolean = false;
	public showMenu: boolean = false;
	public inDirect: boolean = false;
	public inDevices: boolean = false;
	public inBleDevices: boolean = false;
	public inUsers: boolean = false;
	public inDiary: boolean = false;
	public inPlanif: boolean = false;
	public inNotice: boolean = false;
	public inInvitations: boolean = false;
	public inDevicesAdmin: boolean = false;
	public inBleDevicesAdmin: boolean = false;
	public inCentres: boolean = false;
	public inUsersAdmin: boolean = false;
	public residence: ResidenceInterface;
	public residences: Array<ResidenceInterface> = [];
	public filteredResidences: Array<ResidenceInterface> = [];
	public isItemAvailable: boolean = false;
	@ViewChild('search', { static: false }) search: IonSearchbar;
	@ViewChild('list', { static: false }) list: IonList;
	@Input() isLogged: boolean;
	@Input() isAdmin: boolean;
	@Input() isCenter: boolean;
	@Input() isCharge: boolean;
	@Input() isSanitary: boolean;

	constructor(
		private authService: AuthService,
		private router: Router,
		private platform: Platform,
		private http: HttpClient,
		private headerService: HeaderService
	) {}

	async ngOnInit() {
		const { value } = await Storage.get({ key: 'id_token' });
		if (value != null) {
			this.isLogged = true;
		}

		if (this.isLogged) {
			Storage.get({ key: 'center' }).then((center) => {
				if (center.value != null) {
					this.headerService.getResidence(center.value).subscribe(async (residence: ResidenceInterface) => {
						const input = await this.search.getInputElement();
						input.value = residence.name;
					});
				} else {
					this.deactivateRoutes = true;
				}
			});

			this.checkCurrentUrl();
		}
	}

	async ngAfterViewInit() {
		const { value } = await Storage.get({ key: 'id_token' });

		if (value != null) {
			this.isLogged = true;
			this.getRole();
		}
		await this.authService.isLogged().then((val) => {
			if (val.value) {
				this.http.get(API.GET_ACCOUNT).subscribe(
					(account: UserInterface) => {
						this.isAdmin = account.authorities.includes('ROLE_ADMIN');
						this.isCharge = account.authorities.includes('ROLE_PROFESIONAL');
						this.isCenter = account.authorities.includes('ROLE_CENTER');
						this.isLogged = true;
					},
					(error) => {
						this.router.navigate(['login']);
					}
				);
			} else {
				this.isCharge = false;
				this.isAdmin = false;
				this.isLogged = false;
			}
		});
	}

	async getRole() {
		const { value } = await Storage.get({ key: 'role' });
		if (value === 'ROLE_ADMIN') {
			this.isAdmin = true;
			this.headerService.getAllResidences().subscribe((residences: Array<ResidenceInterface>) => {
				for (const residence of residences) {
					const resi = { id: residence.id, name: residence.name, email: residence.email, telephone: residence.telephone };
					this.residences.push(resi);
				}
				//console.log(this.residences);
			});
		} else {
			this.isAdmin = false;
			this.headerService.getAccount().subscribe((account: UserInterface) => {
				for (const residence of account.residence) {
					const resi = { id: residence.id, name: residence.name, email: residence.email, telephone: residence.telephone };
					this.residences.push(resi);
				}
				//console.log(this.residences);
			});
		}

		if (value === 'ROLE_SANITARY') {
			this.isSanitary = true;
		}
	}

	public goToRoute(route: string) {
		if (this.router.url === route) {
			this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
				this.router.navigate([route]);
			});
		} else {
			this.router.navigate([route]);
		}
	}

	public openMenu(ev: Event): void {
		this.showMenu = !this.showMenu;
	}

	public closeMenu(ev: Event): void {
		this.showMenu = false;
	}

	public closeSession() {
		this.platform.ready().then(() => {
			this.authService.logout();
			this.isLogged = false;
			if (this.platform.is('desktop')) {
				this.router.navigate(['/login'], { replaceUrl: true });
			} else {
				this.router.navigate(['/login-app'], { replaceUrl: true });
			}
		});
	}

	public goToIndex() {
		if (this.isAdmin) {
			this.router.navigate(['/centros']);
		} else {
			this.router.navigate(['/directo']);
		}
	}

	public checkCurrentUrl() {
		if (this.router.url.substring(0, 8) === '/directo') {
			this.inDirect = true;
		}
		if (this.router.url.substring(0, 13) === '/dispositivos' && this.router.url.substring(0, 21) !== '/dispositivos/centros') {
			this.inDevices = true;
		}
		if (this.router.url.substring(0, 13) === '/monitores' && this.router.url.substring(0, 21) !== '/monitores/centros') {
			this.inBleDevices = true;
		}
		if (this.router.url.substring(0, 23) === '/usuarios/monitorizados') {
			this.inUsers = true;
		}
		if (this.router.url.substring(0, 7) === '/diario') {
			this.inDiary = true;
		}
		if (this.router.url.substring(0, 7) === '/avisos') {
			this.inNotice = true;
		}
		if (this.router.url.substring(0, 7) === '/planif') {
			this.inPlanif = true;
		}
		if (this.router.url.substring(0, 13) === '/invitaciones') {
			this.inInvitations = true;
		}
		if (this.router.url.substring(0, 21) === '/dispositivos/centros') {
			this.inDevicesAdmin = true;
		}
		if (this.router.url.substring(0, 21) === '/monitores/centros') {
			this.inBleDevicesAdmin = true;
		}
		if (this.router.url.substring(0, 8) === '/centros') {
			this.inCentres = true;
		}
		if (this.router.url === '/usuarios') {
			this.inUsersAdmin = true;
		}
	}

	public getItems(ev: any) {
		// set val to the value of the searchbar
		const val = ev.target.value;
		// if the value is an empty string don't filter the items
		if (val && val.trim() !== '' && ev.target.value.length > 2) {
			this.isItemAvailable = true;
			this.filteredResidences = this.residences.filter((item) => {
				var name = item.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
				return name.toLowerCase().indexOf(val.toLowerCase()) > -1;
			});
		} else {
			this.isItemAvailable = false;
		}
	}

	public async getResidence(ev: any, residence: ResidenceInterface) {
		this.residence = residence;
		const input = await this.search.getInputElement();
		input.value = residence.name;
		this.isItemAvailable = false;
		this.deactivateRoutes = false;
		Storage.set({
			key: 'center',
			value: residence.id.toString()
		});
		this.reloadPage(residence.id.toString());
	}

	public reloadPage(id: string) {
		if (this.inDirect) {
			this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
				this.router.navigate(['/directo']);
			});
		}
		if (this.inDevices) {
			this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
				this.router.navigate(['/dispositivos']);
			});
		}
		if (this.inPlanif) {
			this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
				this.router.navigate(['/planif']);
			});
		}
		if (this.inBleDevices) {
			this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
				this.router.navigate(['/monitores']);
			});
		}
		if (this.inUsers) {
			this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
				this.router.navigate(['/usuarios/monitorizados']);
			});
		}
		if (this.inDiary) {
			this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
				this.router.navigate(['/diario']);
			});
		}
		if (this.inNotice) {
			this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
				this.router.navigate(['/avisos']);
			});
		}
		if (this.inInvitations) {
			this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
				this.router.navigate(['/invitaciones']);
			});
		}
		if (this.inDevicesAdmin) {
			this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
				this.router.navigate(['dispositivos/centros/' + id + '/alta']);
			});
		}
		if (this.inBleDevicesAdmin) {
			this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
				this.router.navigate(['monitores/centros/' + id + '/alta']);
			});
		}
		if (this.inUsersAdmin) {
			this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
				this.router.navigate(['usuarios']);
			});
		}
	}

	public async goToDevicesAdmin() {
		const { value } = await Storage.get({ key: 'center' });

		if (value != null) {
			this.goToRoute('/dispositivos/centros/' + value + '/alta');
		} else {
			this.goToRoute('/dispositivos/centros');
		}
	}
}
